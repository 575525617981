import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "@popperjs/core/dist/cjs/popper.js";
import "bootstrap/dist/js/bootstrap.min.js";
import 'react-toastify/dist/ReactToastify.css';
// import { Provider } from "react-redux";
import App from './App';
import reportWebVitals from "./reportWebVitals";
// import RouterModule from "./routerModule";
// import Store from "./redux/Store/Store";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
