export default function ContactPage() {
    return (
        <>
            <section className="innerBAnner">
                <div className="container">
                    <div className="row ">
                        <div className="col-12">
                            <h1>Contact us</h1>

                        </div>
                        <div className="col-lg-6 col-xxl-4">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text
                                ever since the 1500s, </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="aboutPage">
                <div className="aboutLayerOne">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 order-lg-2 text-end mb-5 mb-lg-0">
                                <figure>
                                    <img src="img/img8.png" alt="" />
                                </figure>
                            </div>
                            <div className="col-lg-7 text-center text-lg-start pe-lg-5">
                                <h1 className="mb-md-4">Contact Details <br />
                                    Get is touch</h1>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text
                                    ever since the 1500s,
                                </p>
                                <h2> <a href="mailto:test@example.com" className="text-secondary">Email: test@example.com</a></h2>
                                <h2><a href="tel:+00000000000" className="text-secondary">Tel: +00000000000</a></h2>

                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}
