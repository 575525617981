import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import './Header.scss';

export default function Header({ handleTCClick, handlePPClick }) {
    const [isMenuOpen, setMenuIsOpen] = useState(false);
    const [SubmenuIsOpen, setSubmenuIsOpen] = useState(false);
    const [OpenModal, setOpenModal] = useState('none');

    const menuOpen = () => {
        setMenuIsOpen(!isMenuOpen);
        console.log(isMenuOpen);
    }
    const menuClose = () => {
        setMenuIsOpen(false);
    }
    const myRef = useRef();
    const dropMenuOpen = (e) => {
        setSubmenuIsOpen(!SubmenuIsOpen);
    }

    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            setSubmenuIsOpen(false);
        }
    };

    // const handleClickInside = () => setSubmenuIsOpen(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });


    const toggolModal = (data) => {
        setOpenModal(data);
    }
    const closeModal = () => {
        setOpenModal('none');
    }
    return (
        <>
            <header>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <Link to="/" className="home"><img src="img/logo.svg" alt="" /></Link>
                        </div>
                        <div className="col-auto ms-auto" >
                            <nav className={isMenuOpen === true ? 'active' : ''} >
                                <div className="d-flex d-xl-none">
                                    <span className="me-auto">MENU</span>
                                    <span className=" closeNav" onClick={() => menuClose()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg>
                                    </span>

                                </div>
                                <ul className="list-unstyled d-xl-flex mb-0"  >
                                    <li onClick={() => menuClose()}><NavLink to="/about" activeClassName="active">About</NavLink></li>
                                    <li onClick={() => menuClose()}><NavLink to="/mission" activeClassName="active">Mission</NavLink></li>
                                    <li onClick={() => menuClose()}><NavLink to="/faq" activeClassName="active">FAQ</NavLink></li>
                                    <li onClick={() => menuClose()}><a onClick={() => toggolModal('pp')} >Privacy Policy</a></li>
                                    <li onClick={() => menuClose()}><a onClick={() => toggolModal('tos')} >Terms of service</a></li>
                                    {/* <li onClick={() => menuClose()}><a onClick={handlePPClick} >Privacy Policy</a></li>
                                    <li onClick={() => menuClose()}><a onClick={handleTCClick} >Terms of service</a></li> */}
                                    <li onClick={(e) => dropMenuOpen(e)} ref={myRef}>
                                        <a >
                                            <span>Contact</span>
                                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" className={SubmenuIsOpen === true && 'active'} >
                                                <path d="M5.75593 5.12713C5.35716 5.58759 4.64284 5.58759 4.24407 5.12713L1.23682 1.65465C0.675944 1.00701 1.136 0 1.99275 0L8.00725 0C8.864 0 9.32406 1.00701 8.76318 1.65465L5.75593 5.12713Z" fill="currentColor" />
                                            </svg>
                                        </a>
                                        {SubmenuIsOpen === true &&
                                            <div className="subMenu">
                                                <ul className="list-unstyled">
                                                    <li onClick={() => menuClose()}><Link to="/contact">General Inquiries</Link></li>
                                                    <li onClick={() => menuClose()}><Link to="/">Support</Link></li>
                                                    <li onClick={() => menuClose()}><Link to="/">Careers</Link></li>
                                                    <li onClick={() => menuClose()}><Link to="/">Press</Link></li>
                                                </ul>
                                            </div>
                                        }
                                    </li>
                                </ul>
                            </nav>

                            <button className="btn d-xl-none shadow-none p-0" onClick={() => menuOpen()}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" /></svg>
                            </button>
                        </div>



                        <div className="col-auto  d-none">
                            <a className="btn btn-xxl btn-outline-secondary rounded-pill d-inline-flex align-items-center shadow-none downloadBtn">
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.2012 0C17.3887 1.68359 16.8262 3.12695 15.7168 4.35938C14.8203 5.35547 13.6973 5.95508 12.3223 6.00781C12.2735 6.00977 12.2266 6.00781 12.1621 6.00781C12.0508 5.42969 12.0723 4.86133 12.2344 4.31445C12.8985 2.05273 14.4043 0.658203 16.6797 0.0742188C16.7871 0.046875 16.8985 0.0253906 17.0059 0C17.0703 0 17.1367 0 17.2012 0Z" fill="currentColor" />
                                    <path d="M22.4609 8.38282C20.4961 9.56837 19.4922 11.2969 19.6816 13.5879C19.8672 15.8496 21.082 17.4336 23.1953 18.3574C23.0879 18.5938 22.9863 18.8301 22.875 19.0625C22.0722 20.7461 21.0937 22.3145 19.8008 23.6699C19.3437 24.1485 18.8242 24.5586 18.1972 24.8027C17.3183 25.1445 16.4707 25.0098 15.6426 24.6231C14.9844 24.3164 14.3144 24.0488 13.5801 23.9746C12.4883 23.8652 11.457 24.0664 10.4707 24.5332C9.96873 24.7715 9.4492 24.961 8.8867 24.9942C8.05857 25.043 7.33592 24.7578 6.68748 24.2637C5.74412 23.5469 5.05271 22.6133 4.41404 21.6367C3.15818 19.7207 2.31052 17.6426 1.96482 15.3731C1.70896 13.6895 1.73435 12.0176 2.28318 10.3828C2.74412 9.00978 3.60545 7.9297 4.75193 7.07032C5.61326 6.42579 6.57029 5.99611 7.65427 5.88282C8.53123 5.79103 9.37302 5.95314 10.2011 6.22657C10.7578 6.41017 11.3086 6.61134 11.8711 6.77345C12.8086 7.04103 13.7226 6.90821 14.6172 6.5547C15.375 6.25587 16.1426 5.99025 16.959 5.89454C18.4219 5.72267 19.7324 6.1211 20.9297 6.93946C21.5097 7.3379 22.0176 7.81642 22.4609 8.38282Z" fill="currentColor" />
                                </svg>

                                <span className="ps-2">Download app</span>
                            </a>
                        </div>
                    </div>
                </div>
            </header>



            {OpenModal === 'pp' &&
                <div className="customModal">
                    <div className="modalSkin" onClick={() => closeModal()}></div>
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <h1>Privacy Policy</h1>
                            <div className="closeModal" onClick={() => closeModal()}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg>
                            </div>
                        </div>
                        <div className="card-body">
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                                <p>
                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Labore nemo blanditiis, asperiores voluptatum autem rem totam cum nihil perferendis expedita nam tempora dolorum veniam quam sed dolor, dicta consequuntur dolore?
                                </p>
                            ))}

                        </div>
                    </div>
                </div>
            }

            {OpenModal === 'tos' &&
                <div className="customModal">
                    <div className="modalSkin" onClick={() => closeModal()}></div>
                    <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                            <h1>Terms of service</h1>
                            <div className="closeModal" onClick={() => closeModal()}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg>
                            </div>
                        </div>
                        <div className="card-body">
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                                <p>
                                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Labore nemo blanditiis, asperiores voluptatum autem rem totam cum nihil perferendis expedita nam tempora dolorum veniam quam sed dolor, dicta consequuntur dolore?
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
