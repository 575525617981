import './HomePage.scss';
import React, { useState } from 'react';

export default function HomePage({ tc, pp, handleTCClick, handlePPClick }) {
    const [layer4, setlayer4] = useState(tc);
    const [layer5, setlayer5] = useState(false);


    return (
        <section className="homePage">
            <div className="layer1 ">
                <div className="container">
                    <div className="row">
                        <div className="col-xxxl-8 mx-auto text-center  ">
                            <h1 className="text-secondary fw-9 pe-xl-5">Mapspot is what’s happening and
                                what people are talking about
                                right now.</h1>
                            <button className="btn btn-xxl btn-secondary rounded-pill d-inline-flex align-items-center px-5">
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.2012 0C17.3887 1.68359 16.8262 3.12695 15.7168 4.35938C14.8203 5.35547 13.6973 5.95508 12.3223 6.00781C12.2735 6.00977 12.2266 6.00781 12.1621 6.00781C12.0508 5.42969 12.0723 4.86133 12.2344 4.31445C12.8985 2.05273 14.4043 0.658203 16.6797 0.0742188C16.7871 0.046875 16.8985 0.0253906 17.0059 0C17.0703 0 17.1367 0 17.2012 0Z" fill="currentColor" />
                                    <path d="M22.4609 8.38281C20.4961 9.56836 19.4922 11.2969 19.6816 13.5879C19.8672 15.8496 21.082 17.4336 23.1953 18.3574C23.0879 18.5937 22.9863 18.8301 22.875 19.0625C22.0722 20.7461 21.0937 22.3145 19.8008 23.6699C19.3437 24.1484 18.8242 24.5586 18.1972 24.8027C17.3183 25.1445 16.4707 25.0098 15.6426 24.623C14.9844 24.3164 14.3144 24.0488 13.5801 23.9746C12.4883 23.8652 11.457 24.0664 10.4707 24.5332C9.96873 24.7715 9.4492 24.9609 8.8867 24.9941C8.05857 25.043 7.33592 24.7578 6.68748 24.2637C5.74412 23.5469 5.05271 22.6133 4.41404 21.6367C3.15818 19.7207 2.31052 17.6426 1.96482 15.373C1.70896 13.6894 1.73435 12.0176 2.28318 10.3828C2.74412 9.00976 3.60545 7.92968 4.75193 7.07031C5.61326 6.42578 6.57029 5.99609 7.65427 5.88281C8.53123 5.79101 9.37302 5.95312 10.2011 6.22656C10.7578 6.41015 11.3086 6.61132 11.8711 6.77343C12.8086 7.04101 13.7226 6.9082 14.6172 6.55468C15.375 6.25586 16.1426 5.99023 16.959 5.89453C18.4219 5.72265 19.7324 6.12109 20.9297 6.93945C21.5097 7.33789 22.0176 7.8164 22.4609 8.38281Z" fill="currentColor" />
                                </svg>

                                <span className="ps-2">Download</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="layer2">
                <div className="container">
                    <figure className="d-block m-0">
                        <img src="img/img1.jpg" alt="" />
                    </figure>
                </div>
            </div>

            <div className="layer3">
                <div className="container">
                    <div className="w-100">
                        <h2 className="text-primary text-center mb-0">How mapspot<sub>TM</sub>  works</h2>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-2 py-5">
                            <figure className="m-0 d-block">
                                <img src="img/img2.png" alt="" />
                            </figure>
                        </div>
                        <div className="col-lg-6 pe-lg-5 py-5">
                            <h3>Enter A Location</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6   py-5">
                            <figure className="m-0 d-block">
                                <img src="img/img3.png" alt="" />
                            </figure>
                        </div>
                        <div className="col-lg-6 pe-lg-5 py-5">
                            <h3>Enter A Location</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-2 py-5">
                            <figure className="m-0 d-block">
                                <img src="img/img4.png" alt="" />
                            </figure>
                        </div>
                        <div className="col-lg-6 pe-lg-5 py-5">
                            <h3>Enter A Location</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4  py-4 py-lg-0">
                            <figure className="m-0 d-block">
                                <img src="img/img5.png" alt="" />
                            </figure>
                        </div>
                        <div className="col-lg-6 pe-lg-5 py-4 py-lg-0">
                            <h3>Download the app</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text
                                ever since the 1500s,
                            </p>
                            <a href="" target="_blank"> <img src="img/appleStore1.svg" alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>

            {pp === true &&
                <div className="layer4 d-flex align-items-center px-3">
                    <div className="bigClose" onClick={handlePPClick}></div>
                    <div className="container py-4">
                        <h2 className="text-center text-center mb-5 ">Data & Location Privacy</h2>
                        <div className="row">
                            <div className="col-lg-6 py-md-5">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <figure>
                                            <img src="img/img6.png" alt="" />
                                        </figure>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="h4">Privacy based business
                                            model.</div>
                                        <p className="text-ass">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 py-md-5">
                                <div className="row align-items-center">
                                    <div className="col-md-6">
                                        <figure>
                                            <img src="img/img7.png" alt="" />
                                        </figure>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="h4">Privacy based business
                                            model.</div>
                                        <p className="text-ass">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 text-center pt-md-5">
                                <button className="btn btn-xxl btn-outline-secondary rounded-pill fw-6 ">See our privacy policy</button>
                            </div>
                        </div>
                    </div>
                </div>

            }
            {tc === true &&
                <div className="layer5 d-flex align-items-center px-3">
                    <div className="bigClose" onClick={handleTCClick}></div>
                    <div className="container text-center py-4">
                        <h2 className="mb-5 pb-md-5">Terms of Service</h2>
                        <div className="row justify-content-around">
                            <div className="col-lg-4 text-center">
                                <div className="h4">First spot free</div>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text.
                                </p>
                            </div>
                            <div className="col-lg-4 text-center">
                                <div className="h4">First spot free</div>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="layer6 py-5 mt-5">
                <div className="container text-center">
                    <h2 className="mb-4 ">Frequently Asked Questions</h2>
                    <div className="row justify-content-around">
                        <div className="col-md-10 text-center">
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                            <div className="pt-4 pt-md-5">
                                <button className="btn btn-xxl btn-outline-secondary rounded-pill fw-6"><span className="px-3">Visit our FAQ</span></button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
